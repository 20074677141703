import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import data from "./festival.json";
import "./festival.css";

const LiveGreetings = () => {
  const [currentFestival, setCurrentFestival] = useState("");
  const [imp, setImp] = useState(false);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const currentDate = new Date();
        const holidays = data.items || []; 
        console.log("Current Date:", currentDate);

        const todayHoliday = holidays.find((holiday) => {
          const holidayDate = new Date(holiday.start.date + "T00:00:00");
          const endDate = new Date(holiday.end.date + "T23:59:59");

          console.log(
            "Holiday Date:",
            holidayDate.toISOString().split("T")[0]
          );

          return currentDate >= holidayDate && currentDate <= endDate;
        });

        // console.log("Today's Holiday:", todayHoliday);

        if (todayHoliday) {
          setImp(todayHoliday.kind === "upd");
          setCurrentFestival(todayHoliday.summary);

          // toast.dark(`Happy ${todayHoliday.summary}!`, {
          //   autoClose: false,
          //   closeButton: "Cancel",
          //   className: "custom_css",
          //   onClose: () => setCurrentFestival(""),
          // });
        } else {
          setCurrentFestival("");
          setImp(false);
        }
      } catch (error) {
        console.error("Error fetching holidays:", error);
      }
    };

    fetchHolidays();
  }, []);

  return (
    <div>
      <div className={`festival_container ${imp ? "show" : "hidden"}`}>
        {imp && <p>{currentFestival}!</p>}
      </div>
      <ToastContainer />
    </div>
  );
};

export default LiveGreetings;
