import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import slide_image_1 from "../../Assets/slider_img/img_1.jpg";
import slide_image_2 from "../../Assets/slider_img/img_2.jpg";
import slide_image_3 from "../../Assets/slider_img/img_3.jpg";
import slide_image_4 from "../../Assets/slider_img/img_4.jpg";
import slide_image_6 from "../../Assets/slider_img/img_6.jpg";
import slide_image_7 from "../../Assets/slider_img/img_7.jpg";
import slide_image_8 from "../../Assets/slider_img/img_8.png";
import slide_image_9 from "../../Assets/slider_img/img_9.jpg";
import slide_image_10 from "../../Assets/slider_img/img_10.jpg";
import slide_image_11 from "../../Assets/slider_img/img_11.png";
import slide_image_12 from "../../Assets/slider_img/img_12.jpg";
import slide_image_13 from "../../Assets/slider_img/img_13.jpg";
import slide_image_14 from "../../Assets/slider_img/img_14.jpg";
import slide_image_15 from "../../Assets/slider_img/img_15.jpg";
import slide_image_16 from "../../Assets/slider_img/img_16.jpg"
import neet1 from "../../Assets/slider_img/img_A.png";
import neet2 from "../../Assets/slider_img/img_B.png";
import neet3 from "../../Assets/slider_img/img_C.png"

import "./Slider.css";

function Slider() {
  return (
    <div className="whole_slider">
      <div className="container">
        <h1 className="heading">Our Results</h1>
        <Swiper
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          autoplay={{ delay: 1000 }}
          className="swiper_container"
        >
          <SwiperSlide className="slidercard">
            <div className="whole_slider_card">
              <img src={slide_image_1} alt="slide_image" />
              <div className="data_slider ">
                <h1>Pratham Pathak</h1>
                <h1>95 percentile</h1>
                <p>Mhtcet</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_2} alt="slide_image" />
              <div className="data_slider">
                <h1>Ritvik Sharma</h1>
                <h1>95.57 percentile</h1>
                <p>Jee Mains</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_3} alt="slide_image" />
              <div className="data_slider">
                <h1>Shlok Meshram</h1>
                <h1>98 percentile</h1>
                <p>Jee </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_4} alt="slide_image" />
              <div className="data_slider">
                <h1>Dhruv Bhanushali</h1>
                <h1>611/720</h1>
                <p>NEET</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_6} alt="slide_image" />
              <div className="data_slider yehw">
                <h1>Ojas Koni</h1>
                <h1>94 percentile</h1>
                <p>Mhtcet</p>
              </div>
            </div>
          </SwiperSlide>
        
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_8} alt="slide_image" />
              <div className="data_slider">
                <h1>Tanushree Lagu</h1>
                <h1>99.63 percentile</h1>
                <p>Mhtcet</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_13} alt="slide_image" />
              <div className="data_slider">
                <h1>Tania</h1>
                <h1>92.81 percentile</h1>
                <p>Mhtcet</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_10} alt="slide_image" />
              <div className="data_slider">
                <h1>Hardik Dayate</h1>
                <h1>91 percentile</h1>
                <p>Mhtcet</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_14} alt="slide_image" />
              <div className="data_slider">
              <h1>Manasvi Kate</h1>
                <h1>96 percentile</h1>
                <p>Mhtcet</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_15} alt="slide_image" />
              <div className="data_slider yehw">
                <h1>Isha Kokate</h1>
                <h1>97.52 percentile</h1>
                <p>Mhtcet</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={neet1} alt="slide_image" />
              <div className="data_slider">
                <h1>Eshwari Tambe</h1>
                <h1>621/720</h1>
                <p>NEET</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={neet2} alt="slide_image" />
              <div className="data_slider">
                <h1>Neha Anant</h1>
                <h1>650/720</h1>
                <p>NEET</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={neet3} alt="slide_image" />
              <div className="data_slider">
                <h1>Sheetal Lahmate</h1>
                <h1>622/720</h1>
                <p>NEET</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_16} alt="slide_image" />
              <div className="data_slider yehw">
                <h1>Rohan Jena </h1>
                <h1>91 percentile</h1>
                <p>Jee Mains(2025)</p>
              </div>
            </div>
          </SwiperSlide>
          
         
          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_12} alt="slide_image" />
              <div className="data_slider">
                <h1>Samiksha Dabhlikar</h1>
                <h1>602/720</h1>
                <p>NEET</p>
              </div>
            </div>
          </SwiperSlide>

          
          
          

          <SwiperSlide>
            <div className="whole_slider_card">
              <img src={slide_image_11} alt="slide_image" />
              <div className="data_slider">
                <h1>Jagjeet Singh </h1>
                <h1>95.15 percentile</h1>
                <p>Jee Mains</p>
              </div>
            </div>
          </SwiperSlide>


          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}

export default Slider;
