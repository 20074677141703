import React, { useState, useRef, useEffect } from "react";
import "./Form.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import emailjs from "@emailjs/browser";
const Contactus = ({ setpop, popup }) => 
{
  // console.log(contactusani);
  const [name, setName] = useState("");
  // const [activechat, setchat] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [interestedService, setInterestedService] = useState("");
  const [location, setlocation] = useState("");
  const secondform = useRef();

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("called");
    if (!isValidPhoneNumber(phone)) {
      // console.log("yaha aaya mai");
      toast.warning("Please Enter a Valid Phone number and then Retry", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });
      secondform.current.reset();
      return;
    }
    if (isValidEmail(email)) {
      toast.warning("Processing Your Request....", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-custom-style",
      });
  
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const { data } = await axios.post(
          "https://i-science-ser.vercel.app/api/v1/forms/Contactus",
          {
            name: name,
            email: email,
            phoneno: phone,
            courses: interestedService,
            question: message,
          },
          config
        );
        // console.log(data);
      } catch (error) {
        toast.warning({
          title: "Error Occured!",
          description: error.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
          className: "toast-custom-style",
        });
        setName("");
        setEmail("");
        setInterestedService("");
        setPhone("");
        setMessage("");
        return;
      }
      await emailjs
        .sendForm(
          "service_prnvolm",
          "template_wpskgdr",
          secondform.current,
          "8lJSuYvg8DqMtBcn9"
        )
        .then(
          async (result) => {
            toast.success(
              "Thank you, Your Details has been Submitted will get back to you!",
              {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "toast-custom-style",
              }
            );
            
          }
        )
        .catch((err) => {
          console.log(err);
        });
      // try {
      //   const config = {
      //     headers: {
      //       "Content-type": "application/json",
      //     },
      //   };
      //   const { data } = await axios.post(
      //     "http://localhost:5000/sendmessage",
      //     {
      //       name: name,
      //       email: email,
      //       number: phone,
      //       course: interestedService,
      //       question: message,
      //     },
      //     config
      //   );
      //   // console.log(data);
      // } catch (e) {
      //   console.log(e);
      // }
      // },
      // (error) => {
      //   console.log(error.text);
      //   toast.error("Sorry!!! , Some Error Occurred, Please Try Again", {
      //     position: "top-left",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     className: "toast-custom-style",
      //   });
      // }
      // );
      // } else {
      // toast.warning("Please Enter a Valid Email and then Retry", {
      //   position: "top-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   // closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   // theme:"dark",
      //   className: "toast-custom-style",
      // });
      // }
      secondform.current.reset();
      setName("");
      setEmail("");
      setInterestedService("");
      setPhone("");
      setMessage("");
      setTimeout(() => {
        setpop(false);
      }, 1000);
    }
  
    function isValidEmail(email) {
      const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return emailFormat.test(email);
    }
  };
  
  return (
    <>
      <div className="tempx"></div>
      <div className="whole_contactus">
        <div className="right_contactus">
          <div className="form-container">
            <div className="form-content">
              <button className="close-button" onClick={() => setpop(false)}>
                X
              </button>
              <form
                className="send-message-form"
                ref={secondform}
                onSubmit={handleSubmit}
              >
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  // placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required={true}
                />
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  // placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                />

                <label htmlFor="phone">Phone Number*</label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required={true}
                />

                <label htmlFor="interestedcourse">Interested Course*</label>
                <select
                  id="interestedService"
                  value={interestedService}
                  onChange={(e) => setInterestedService(e.target.value)}
                  required={true}
                >
                  <option value="">Select Interested Course</option>
                  <option value="11th or 12th Science">
                    11th or 12th Science
                  </option>
                  <option value="Jee Mains/Adv.">JEE Mains/Adv.</option>
                  <option value="Neet">NEET</option>
                  <option value="Mhtcet">MHTCET</option>
                </select>
                <label htmlFor="Question">Question/Enquiry* </label>
                <textarea
                  id="message"
                  // placeholder="How can we help?"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required={true}
                ></textarea>
                <div className="btn_submit_full">
                  <button type="submit">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      {/* <Map /> */}
      {/* <Map/> */}
    </>
  );
};

export default Contactus;
